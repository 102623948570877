















import Vue from 'vue'
import Component from 'vue-class-component'

import Content from '@/components/Content.vue'
import Toolbar from '@/components/Layout/Toolbar.vue'
import FormButton from '@/components/Form/Button.vue'

@Component({
  components: {
    Content,
    Toolbar,
    FormButton
  }
})
export default class CancelDeleded extends Vue {

}
